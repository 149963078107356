// 3rd
import { z } from 'zod';

// App - Types
import {
  AllowedSourcesForInlineCommentFeedback,
  isAllowedToSendInlineCommentFeedbackForSource,
} from '../../types/feedback';
import type { SendSecurityInlineCommentFeedback } from '../../types/feedback';

export const ZodSendSecurityInlineCommentFeedbackDto = z.object({
  securityRequirementIds: z.string().array(),
  securityStoryThreatIds: z.string().array(),
  securityStoryId: z.string(),
});

export type SendSecurityInlineCommentFeedbackDto = z.infer<
  typeof ZodSendSecurityInlineCommentFeedbackDto
>;

export const castSendSecurityInlineCommentFeedbackToSendSecurityInlineCommentFeedbackDto = (
  storyId: string,
  payload: SendSecurityInlineCommentFeedback
): SendSecurityInlineCommentFeedbackDto => {
  const validThreats = (payload.threats || []).some((t) =>
    isAllowedToSendInlineCommentFeedbackForSource(t.relatedKnowledgeItemSource)
  );
  const validRequirements = (payload.requirements || []).some((r) =>
    isAllowedToSendInlineCommentFeedbackForSource(r.relatedKnowledgeItemSource)
  );

  if (!validThreats && !validRequirements)
    throw new Error(
      `Feedback creation is allowed for the following sources => ${AllowedSourcesForInlineCommentFeedback.join(', ')}.`
    );

  return {
    securityStoryId: storyId,
    securityRequirementIds: (payload.requirements || []).flatMap((req) =>
      isAllowedToSendInlineCommentFeedbackForSource(req.relatedKnowledgeItemSource) ? [req.id] : []
    ),
    securityStoryThreatIds: (payload.threats || []).flatMap((threat) =>
      isAllowedToSendInlineCommentFeedbackForSource(threat.relatedKnowledgeItemSource)
        ? [threat.id]
        : []
    ),
  };
};
