// App - Types
import type { KnowledgeSource } from '@/types/integration/knowledge-source';

// App - Other
import Locale from '@/locale/en.json';

const locale = Locale.components.translators.integration['knowledge-source'];

type PluralizationOptions = {
  count: number;
};

export const translateKnowledgeSourceToSourceEntity = (
  source: KnowledgeSource,
  options?: PluralizationOptions
): string => {
  const useSingular = options?.count === 1;

  if (source === 'confluence') return useSingular ? locale['Space'] : locale['Spaces'];

  if (source === 'google_drive') return useSingular ? locale['Folder'] : locale['Folders'];

  if (source === 'jira') return useSingular ? locale['Project'] : locale['Projects'];

  if (source === 'trello') return useSingular ? locale['Board'] : locale['Boards'];

  if (source === 'linear') return useSingular ? locale['Project'] : locale['Projects'];

  if (source === 'github') return useSingular ? locale['Repository'] : locale['Repositories'];

  if (source === 'share_point') return useSingular ? locale['Folder'] : locale['Folders'];

  if (source === 'notion') return useSingular ? locale['Page'] : locale['Pages'];

  if (source === 'gitlab') return useSingular ? locale['Repository'] : locale['Repositories'];

  if (source === 'azure_devops_wiki_page')
    return useSingular ? locale['Folder'] : locale['Folders'];

  if (source === 'azure_devops_work_item') return useSingular ? locale['Board'] : locale['Boards'];

  if (source === 'azure_devops_repository')
    return useSingular ? locale['Repository'] : locale['Repositories'];

  return useSingular ? locale['Project'] : locale['Projects'];
};

export const translateKnowledgeSourceToScrappedEntity = (
  source: KnowledgeSource,
  options?: PluralizationOptions
): string => {
  const useSingular = options?.count === 1;

  if (source === 'confluence') return useSingular ? locale['Document'] : locale['Documents'];

  if (source === 'google_drive') return useSingular ? locale['Document'] : locale['Documents'];

  if (source === 'jira') return useSingular ? locale['Ticket'] : locale['Tickets'];

  if (source === 'trello') return useSingular ? locale['Ticket'] : locale['Tickets'];

  if (source === 'linear') return useSingular ? locale['Ticket'] : locale['Tickets'];

  if (source === 'github') return useSingular ? locale['Issue'] : locale['Issues'];

  if (source === 'share_point') return useSingular ? locale['Document'] : locale['Documents'];

  if (source === 'notion') return useSingular ? locale['Document'] : locale['Documents'];

  if (source === 'gitlab') return useSingular ? locale['File'] : locale['Files'];

  if (source === 'azure_devops_wiki_page')
    return useSingular ? locale['Document'] : locale['Documents'];

  if (source === 'azure_devops_work_item')
    return useSingular ? locale['Ticket'] : locale['Tickets'];

  if (source === 'azure_devops_repository')
    return useSingular ? locale['Pull request'] : locale['Pull requests'];

  return useSingular ? locale['Document'] : locale['Documents'];
};
