// App - Types
import type { Type } from './type';

export const Kinds = ['document', 'ticket', 'UNKNOWN'] as const;

export type Kind = (typeof Kinds)[number];

const DOCUMENT_TYPES: ReadonlyArray<Type> = [
  'azure_devops_wiki_page',
  'confluence_page',
  'google_drive',
  'share_point',
] as const;

const TICKET_TYPES: ReadonlyArray<Type> = [
  'azure_devops_work_item',
  'jira_issue',
  'linear_issue',
  'trello_card',
  'github_issue',
] as const;

export const castTypeToKind = (type: Type): Kind => {
  if (DOCUMENT_TYPES.includes(type)) return 'document';

  if (TICKET_TYPES.includes(type)) return 'ticket';

  return 'UNKNOWN';
};
