// 3rd
import { z } from 'zod';

// App - Types
import type { SendMissingInformationSlackMessage } from '../../types/slack';

export const ZodSendMissingInformationSlackMessageDto = z.object({
  applicationId: z.string().optional(),
  integrationConfigurationId: z.string(),
  missingInformationIds: z.string().array(),
  securityStoryId: z.string(),
  userEmail: z.string(),
});

export type SendMissingInformationSlackMessageDto = z.infer<
  typeof ZodSendMissingInformationSlackMessageDto
>;

export const castSendMissingInformationSlackMessageToSendMissingInformationSlackMessageDto = (
  payload: SendMissingInformationSlackMessage
): SendMissingInformationSlackMessageDto => {
  return {
    applicationId: payload.applicationId,
    integrationConfigurationId: payload.recipient.workspaceId,
    missingInformationIds: payload.missingInformationIds || [],
    securityStoryId: payload.storyId,
    userEmail: payload.recipient.email || '',
  };
};
