// 3rd
import { z } from 'zod';

// App - Types
import { ZodIdDto, castIdToIdDto } from '@/types/knowledge-item/dtos/id';
import { isAllowedToSendCommentFeedbackForSource } from '../../types/feedback';
import type { SendMissingInformationCommentFeedback } from '../../types/feedback';

export const ZodCreateMissingInformationFeedbackDto = z
  .object({
    missingInformationIds: z.string().array(),
    securityStoryId: z.string(),
  })
  .merge(ZodIdDto);

export type CreateMissingInformationFeedbackDto = z.infer<
  typeof ZodCreateMissingInformationFeedbackDto
>;

export const castSendMissingInformationCommentFeedbackToCreateMissingInformationFeedbackDto = (
  storyId: string,
  payload: SendMissingInformationCommentFeedback
): CreateMissingInformationFeedbackDto => {
  if (!isAllowedToSendCommentFeedbackForSource(payload.recipientKnowledgeItemId.source))
    throw new Error(
      `Feedback creation is not allowed for this source => ${payload.recipientKnowledgeItemId.source}.`
    );

  const idDto = castIdToIdDto(payload.recipientKnowledgeItemId);

  return {
    ...idDto,
    securityStoryId: storyId,
    missingInformationIds: payload.missingInformationIds || [],
  };
};
