// 3rd
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';

// App - Types
import type { SendMissingInformationCommentFeedback } from '../types/feedback';
import {
  ZodCreateMissingInformationFeedbackDto,
  castSendMissingInformationCommentFeedbackToCreateMissingInformationFeedbackDto,
} from './dtos/send-missing-information-comment-feedback';

// App - Other
import { apiClient } from '@/config/lib/api-client';
import { queryClient } from '@/config/lib/react-query';
import { FEEDBACK_QUERY_KEYS } from '../config/react-query-key-factory';

// ###########
// Request DTO
// ###########

const ZodRequestPayloadDto = ZodCreateMissingInformationFeedbackDto;

type RequestPayloadDto = z.infer<typeof ZodRequestPayloadDto>;

export { ZodRequestPayloadDto as ZodSendMissingInformationCommentFeedbackRequestPayloadDto };
export type { RequestPayloadDto as SendMissingInformationCommentFeedbackRequestPayloadDto };

// ############
// Response DTO
// ############

const ZodResponseDto = z.object({
  securityFeedbackId: z.string(),
});

type ResponseDto = z.infer<typeof ZodResponseDto>;

export { ZodResponseDto as ZodSendMissingInformationCommentFeedbackResponseDto };
export type { ResponseDto as SendMissingInformationCommentFeedbackResponseDto };

// #######
// Request
// #######

export const sendMissingInformationCommentFeedback = async (
  storyId: string,
  model: SendMissingInformationCommentFeedback
): Promise<string> => {
  try {
    const payload: RequestPayloadDto = ZodRequestPayloadDto.parse(
      castSendMissingInformationCommentFeedbackToCreateMissingInformationFeedbackDto(storyId, model)
    );
    const res = await apiClient.post(`/SecurityFeedback/CreateMissingInformationComment`, payload);
    const parsedRes: ResponseDto = ZodResponseDto.parse(res);

    return parsedRes.securityFeedbackId;
  } catch (e) {
    console.error(e);

    return Promise.reject(e);
  }
};

// ####
// Hook
// ####

type UseSendMissingInformationCommentFeedback = {
  storyId: string;
  onCreateStart?: () => void;
  onCreateSuccess?: (payload: SendMissingInformationCommentFeedback) => void;
  onCreateFailed?: (error: Error) => void;
};

export const useSendMissingInformationCommentFeedback = ({
  storyId,
  onCreateStart,
  onCreateSuccess,
  onCreateFailed,
}: UseSendMissingInformationCommentFeedback) => {
  const { mutate, isPending, isError } = useMutation({
    mutationFn: async (sendCommentFeedbackPayload: SendMissingInformationCommentFeedback) => {
      await sendMissingInformationCommentFeedback(storyId, sendCommentFeedbackPayload);

      await queryClient.invalidateQueries({
        queryKey: FEEDBACK_QUERY_KEYS.knowledgeItemFeedback(
          sendCommentFeedbackPayload.recipientKnowledgeItemId
        ),
      });

      return sendCommentFeedbackPayload;
    },
    onMutate: () => onCreateStart?.(),
    onSuccess: (payload) => onCreateSuccess?.(payload),
    onError: (error) => onCreateFailed?.(error),
  });

  return {
    sendMissingInformationCommentFeedback: mutate,
    isSendingMissingInformationComment: isPending,
    didSendMissingInformationCommentErrored: isError,
  };
};
