// 3rd
import { z } from 'zod';

// App - Types
import { ZodIdDto, castIdToIdDto } from '@/types/knowledge-item/dtos/id';
import { isAllowedToSendCommentFeedbackForSource } from '../../types/feedback';
import type { SendSecurityCommentFeedback } from '../../types/feedback';

export const ZodCreateSecurityFeedbackDto = z
  .object({
    securityRequirementIds: z.string().array(),
    securityStoryThreatIds: z.string().array(),
    securityStoryId: z.string(),
  })
  .merge(ZodIdDto);

export type CreateSecurityFeedbackDto = z.infer<typeof ZodCreateSecurityFeedbackDto>;

export const castSendSecurityCommentFeedbackToCreateSecurityFeedbackDto = (
  storyId: string,
  payload: SendSecurityCommentFeedback
): CreateSecurityFeedbackDto => {
  if (!isAllowedToSendCommentFeedbackForSource(payload.recipientKnowledgeItemId.source))
    throw new Error(
      `Feedback creation is not allowed for this source => ${payload.recipientKnowledgeItemId.source}.`
    );

  const idDto = castIdToIdDto(payload.recipientKnowledgeItemId);

  return {
    ...idDto,
    securityStoryId: storyId,
    securityRequirementIds: payload.requirementIds || [],
    securityStoryThreatIds: payload.threatIds || [],
  };
};
