// 3rd
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';

// App - Types
import type { SendSecurityInlineCommentFeedback } from '../types/feedback';
import {
  ZodSendSecurityInlineCommentFeedbackDto,
  castSendSecurityInlineCommentFeedbackToSendSecurityInlineCommentFeedbackDto,
} from './dtos/send-security-inline-comment-feedback';

// App - Other
import { apiClient } from '@/config/lib/api-client';

// ###########
// Request DTO
// ###########

const ZodRequestPayloadDto = ZodSendSecurityInlineCommentFeedbackDto;

type RequestPayloadDto = z.infer<typeof ZodRequestPayloadDto>;

export { ZodRequestPayloadDto as ZodSendInlineCommentFeedbackRequestPayloadDto };
export type { RequestPayloadDto as SendInlineCommentFeedbackRequestPayloadDto };

// ############
// Response DTO
// ############

const ZodResponseDto = z.object({
  securityFeedbackIds: z.string().array(),
});

type ResponseDto = z.infer<typeof ZodResponseDto>;

export { ZodResponseDto as ZodSendInlineCommentFeedbackResponseDto };
export type { ResponseDto as SendInlineCommentFeedbackResponseDto };

// #######
// Request
// #######

export const sendSecurityInlineCommentFeedback = async (
  storyId: string,
  model: SendSecurityInlineCommentFeedback
): Promise<string[]> => {
  try {
    const payload: RequestPayloadDto = ZodRequestPayloadDto.parse(
      castSendSecurityInlineCommentFeedbackToSendSecurityInlineCommentFeedbackDto(storyId, model)
    );
    const res = await apiClient.post(`/SecurityFeedback/CreateInlineComments`, payload);
    const parsedRes: ResponseDto = ZodResponseDto.parse(res);

    return parsedRes.securityFeedbackIds;
  } catch (e) {
    console.error(e);

    return Promise.reject(e);
  }
};

// ####
// Hook
// ####

type UseSendSecurityInlineCommentFeedback = {
  storyId: string;
  onCreateStart?: () => void;
  onCreateSuccess?: (payload: SendSecurityInlineCommentFeedback) => void;
  onCreateFailed?: (error: Error) => void;
};

export const useSendSecurityInlineCommentFeedback = ({
  storyId,
  onCreateStart,
  onCreateSuccess,
  onCreateFailed,
}: UseSendSecurityInlineCommentFeedback) => {
  const { mutate, isPending, isError } = useMutation({
    mutationFn: async (payload: SendSecurityInlineCommentFeedback) => {
      await sendSecurityInlineCommentFeedback(storyId, payload);

      return payload;
    },
    onMutate: () => onCreateStart?.(),
    onSuccess: (payload) => onCreateSuccess?.(payload),
    onError: (error) => onCreateFailed?.(error),
  });

  return {
    sendSecurityInlineCommentFeedback: mutate,
    isSendingSecurityInlineComment: isPending,
    didSendSecurityInlineCommentErrored: isError,
  };
};
