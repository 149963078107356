// 3rd
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';

// App - Types
import type { SendMissingInformationSlackMessage } from '../types/slack';
import {
  castSendMissingInformationSlackMessageToSendMissingInformationSlackMessageDto,
  ZodSendMissingInformationSlackMessageDto,
} from './dtos/send-missing-information-slack-message';

// App - Other
import { apiClient } from '@/config/lib/api-client';

// ###########
// Request DTO
// ###########

const ZodRequestPayloadDto = ZodSendMissingInformationSlackMessageDto;

type RequestPayloadDto = z.infer<typeof ZodRequestPayloadDto>;

export { ZodRequestPayloadDto as ZodSendSlackMessageRequestPayloadDto };
export type { RequestPayloadDto as SendSlackMessageRequestPayloadDto };

// #######
// Request
// #######

export const sendMissingInformationSlackMessage = async (
  model: SendMissingInformationSlackMessage
): Promise<void> => {
  try {
    const payload: RequestPayloadDto = ZodRequestPayloadDto.parse(
      castSendMissingInformationSlackMessageToSendMissingInformationSlackMessageDto(model)
    );
    await apiClient.post(`/Slack/SendMissingInformationMessage`, payload);

    return;
  } catch (e) {
    console.error(e);

    return Promise.reject(e);
  }
};

// ####
// Hook
// ####

type UseSendMissingInformationSlackMessage = {
  onCreateStart?: () => void;
  onCreateSuccess?: () => void;
  onCreateFailed?: (error: Error) => void;
};

export const useSendMissingInformationSlackMessage = ({
  onCreateStart,
  onCreateSuccess,
  onCreateFailed,
}: UseSendMissingInformationSlackMessage = {}) => {
  const { mutate, isPending, isError } = useMutation({
    mutationFn: async (sendMessagePayload: SendMissingInformationSlackMessage) =>
      await sendMissingInformationSlackMessage(sendMessagePayload),
    onMutate: () => onCreateStart?.(),
    onSuccess: () => onCreateSuccess?.(),
    onError: (error) => onCreateFailed?.(error),
  });

  return {
    sendMissingInformationSlackMessage: mutate,
    isSendingMissingInformationSlackMessage: isPending,
    didSendMissingInformationSlackMessageErrored: isError,
  };
};
