// 3rd
import type { DateTime } from 'luxon';

// App - Types
import type { KnowledgeSource } from '@/types/integration/knowledge-source';
import type { Id } from '@/types/knowledge-item/id';

export type Feedback = {
  id: string;
  knowledgeItemId: Id;
  content: string;
  lastModified: DateTime;
};

export type SendMissingInformationCommentFeedback = {
  recipientKnowledgeItemId: Id;
  recipientLinkHref: string;
  missingInformationIds?: string[];
};

export type SendSecurityCommentFeedback = {
  recipientKnowledgeItemId: Id;
  recipientLinkHref: string;
  requirementIds?: string[];
  threatIds?: string[];
};

export type SendSecurityInlineCommentFeedback = {
  requirements?: {
    id: string;
    relatedKnowledgeItemSource: KnowledgeSource;
  }[];
  threats?: {
    id: string;
    relatedKnowledgeItemSource: KnowledgeSource;
  }[];
};

export type InsertParagraphFeedback = {
  recipientKnowledgeItemId: Id;
  recipientLinkHref: string;
  requirementIds?: string[];
  threatIds?: string[];
};

export type CreateAzureWorkItemFeedback = {
  title: string;
  content: string;
  recipientIntegrationConfigurationId: string;
  projectName: string;
};

export type CreateTicketFeedback = {
  requirementIds?: string[];
  threatIds?: string[];
};

export const AllowedSourcesForCommentFeedback: ReadonlyArray<KnowledgeSource> = [
  'confluence',
  'google_drive',
  'jira',
  'azure_devops_wiki_page',
  'azure_devops_work_item',
  'share_point',
] as const;

export const isAllowedToSendCommentFeedbackForSource = (source: KnowledgeSource): boolean => {
  return AllowedSourcesForCommentFeedback.includes(source);
};

export const AllowedSourcesForInlineCommentFeedback: ReadonlyArray<KnowledgeSource> = [
  'confluence',
] as const;

export const isAllowedToSendInlineCommentFeedbackForSource = (source: KnowledgeSource): boolean => {
  return AllowedSourcesForInlineCommentFeedback.includes(source);
};

export const AllowedSourcesForParagraphInsertionFeedback: ReadonlyArray<KnowledgeSource> = [
  'confluence',
  'google_drive',
  'share_point',
] as const;

export const isAllowedToInsertParagraphFeedbackForSource = (source: KnowledgeSource): boolean => {
  return AllowedSourcesForParagraphInsertionFeedback.includes(source);
};

export const AllowedSourcesForCreateTicketFeedback: ReadonlyArray<KnowledgeSource> = [
  'jira',
] as const;

export const isAllowedToCreateTicketFeedbackForSource = (source: KnowledgeSource): boolean => {
  return AllowedSourcesForCreateTicketFeedback.includes(source);
};
